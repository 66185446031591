import { createAction, props } from "@ngrx/store";

export const selectThumbnail = createAction(
    '[Select the thumbnail image]', props<{ greenId: any }>()
)
export const selectedHoleAction = createAction(
    '[SelectedHoles]', props<{ selectedHoles: any[] }>()
)
export const holesUpdatedAction = createAction(
    '[UnselectedHoles]', props<{ holesUpdated: boolean }>()
)

export const closeStimpAction = createAction(
    '[Close Stimp table]', props<{ closeStimpTable: boolean }>()
)

export const activeHolesAction = createAction(
    '[Store active holes]', props<{ activeHoles: any }>()
)
export const UpdateHolesAction = createAction(
    '[Delete holes]', props<{ holesApi: boolean }>()
)
export const manageGreenThumbnailAction = createAction(
    '[Get All manage Green thumbnail]', props<{ greenThumbnailData: any }>()
)
export const clearSelectionAction = createAction(
    '[clearSelectionAction]', props<{ clearSelection: boolean }>()
)

export const holsTableHoleAction = createAction(
    '[Get all holes]', props<{ holesData: any[] }>()
)

export const enableDisableholeAction = createAction(
    '[Enable/Disable holes]', props<{enableDisable: boolean}>()
)