import { createSelector, createFeatureSelector } from "@ngrx/store";
import { User } from "../models/user.model";

export const user = createFeatureSelector<User>('user')
export const impersonate = createFeatureSelector<boolean>('impersonate')

export const selectUserCollection = createSelector(
    user,
    (user)=>user
)   

export const selectUserImpersonate = createSelector(
    impersonate,
    (impersonate) => impersonate
)