<form class="client-dropdown-container">
    <mat-form-field class="custom-width" [ngClass]="{'imp-custom-width': page=='impersonateDialog'}" appearance="fill">
        <input style="padding-left: 0px !important;" class="upperCase clients" 
        [ngClass]="{'imp-clients': page=='impersonateDialog'}" 
        (click)="selectText($event)"
        id="clientInput"
        placeholder="SELECT CLIENT" type="text" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete [panelWidth]="isMobile ? 240 : 300" autoActiveFirstOption #auto="matAutocomplete">
            <mat-option class="dropdown-option " *ngFor="let lastItem=last let option of filteredOptions | async" [value]="option.name" (click)="selectValue(option.name,option.id)">
                <p class="list_name upperCase">{{option.name}}</p>
                <hr class="divider" *ngIf="!lastItem">
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>