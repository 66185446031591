<div class="resources_div title_1" id="resources">
    <div class="modal-body  flex-layout">
        <div class="modal-title close-container" #template (click)="closeModal(false)">
            <button type="button" class="close" aria-label="Close"></button>
        </div>
        <div class="title1 ">
            <div class="playbook ">
                <a (click)="viewPlaybook()"
                    target="blank ">
                    <h1 class="title">PLAYBOOK</h1>
                </a>
                <p>Custom training aid for your staff to understand pinsheets.
                </p>
            </div>
            <!-- <div class="ezlo ">
                <h1>EZLO APP</h1>
                <p>Quick field access from your device to edit locations.
                </p>
                <div class="image_div ">
                    <a href="https://apps.apple.com/us/app/ezlocator-ezlo/id1333172070" target="blank">
                        <img class="appstore" src="../../../assets/images/app_store.svg ">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.ezlocator.ezlo" target=" blank ">
                        <img class="playstore " src="../../../assets/images/play_store.png ">
                    </a>

                </div>
            </div> -->
            <div class="training-video">
                <a (click)="navigateToTrainingLibrary()"
                    target="blank ">
                    <h1 class="title">TRAINING VIDEO LIBRARY</h1>
                </a>
                <p>Access training videos here for further help.
                </p>
            </div>
            <div class="connect" *ngIf="selectedClientId">
                <h1>MEMBER ACCESS HUB</h1>
                <p>Easy mobile access for your daily hole locations</p>
                <div class="member-access-buttons">
                    <img src="../../../assets/images/Download Instructions Button.png" (click)="downloadMemberAccessFlyer($event)">
                    <img src="../../../assets/images/Download QR Code Button.png" (click)="onDownloadQRCodeClick($event)">
                    <qrcode style="display: none;" id="member-url-qr-code" [qrdata]="memberFlyerQRCodeURL" [width]="500"></qrcode>
                </div>
            </div>
        </div>
        <div class="title2 ">
            <!-- <div class="playbook ">
                <a (click)="viewPlaybook()"
                    target="blank ">
                    <h1 class="title">PLAYBOOK</h1>
                </a>
                <p>Custom training aid for your staff to understand pinsheets.
                </p>
            </div> -->
            <div class="connect ">
                <a href="https://ezlocator.com/connected-club/" target="blank">
                    <h1 class="title">CONNECTED CLUB</h1>
                </a>
                <p>All of our integration partners to enhance your experience.
                </p>
            </div>
            <div class="connect">
                <a (click)="downloadMyFile()" target="blank">
                    <h1 class="title">USER GUIDE</h1>
                </a>
                <p>Comprehensive user guide for all features of ezlocator.
                </p>
            </div>
            <div class="connect" *ngIf="selectedClientId">
                <h1>MOBILE PINSHEET QUICK ACCESS</h1>
                <div class="member-password-container" *ngIf="isSuperAdmin">
                    <p class="member-password-text">{{clientProfileData.MemberPassword}}</p>
                </div>
                <div class="member-access-buttons">
                    <img src="../../../assets/images/Mobile Pinsheet Button.png" (click)="openMemberPinsheet($event)">
                </div>
            </div>
        </div>
        <div class="title_3 ">
            <div class="additional">
                <a href="{{cdn_url}}/assets/MEMBER-ACCESS.pdf" target="blank">
                    <h1 class="title">ADDITIONAL <br>DOCUMENTS
                    </h1>
                </a>
            </div>
            <div class="contact ">
                <h1 class="title">CONTACT</h1>
                <p class="guideline "><a href="mailto:support@ezlocator.com" target="_blank">EMAIL US</a></p>
                <p class="guideline ">CALL US: 972-231-4040</p>
            </div>
        </div>
    </div>
</div>
<ng-template #selectPlaybookRef>
    <div class="modal-header">
        <h3 mat-dialog-title>Select Playbook</h3>
        <div class="modal-close" #template mat-dialog-close (click)="closePlaybookDialog()">
            <button type="button" class="close" aria-label="Close"></button>
        </div>
    </div>
    <div class="dropdown-container">
        <app-dropdown class="app-dropdown" [label]="selectedPlaybook" [isFont]="true"
            (setSelected)="setSelectedPlaybook($event)" (selectedId)="onPlaybookSelection($event)"
            [dropdownArr]="playbookNameArray">
        </app-dropdown>
    </div>
</ng-template>