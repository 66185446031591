import { createReducer, on } from "@ngrx/store";
import { managePinsheetdetails, toggleAvoidableRadius, toggleFuturePins, toggleHistoryFlag, redirectPinsheet,togglePinsheetHistory,getPinsheetHistory } from "../actions/manage-pinsheet.action";
import { ManagePinsheet } from "../models/manage-pinsheet.model";

export const initialState: ManagePinsheet[] = []
export const redirectInitialState: boolean = false
export const historyInitialState: boolean = false
export const historychangedState: boolean = false

export const managePinsheetReducer = createReducer(
    initialState,
    on(managePinsheetdetails, (state, { managePinsheet }) => {
        return managePinsheet
    }),
    on(toggleAvoidableRadius, (state, { avoidableRadius }) => {
        return Object.assign(
            {}, state, { 'AvoidableRadius': avoidableRadius }
        )

    }),
    on(toggleFuturePins, (state, { futurePins }) => {
        return Object.assign(
            {}, state, { 'FuturePins': futurePins }
        )

    }),
    on(toggleHistoryFlag, (state, { historyFlag }) => {
        return Object.assign(
            {}, state, { 'HistoryFlag': historyFlag }
        )

    }),
)

export const manageRedirectReducer = createReducer(
    redirectInitialState,
    on(redirectPinsheet, (state, { redirect }) => redirect)
)
export const pinsheetHistoryReducer = createReducer(
    historyInitialState,
    on(togglePinsheetHistory, (state, { pinsheetHistory }) => pinsheetHistory)
)
export const changedHistoryReducer = createReducer(
    historychangedState,
    on(getPinsheetHistory, (state, { dayHistory }) => dayHistory)
)