import { createReducer, on } from "@ngrx/store";
import { impersonateAction, userDetails } from "../actions/user.actions";
import { User } from "../models/user.model";

export const initialState: ReadonlyArray<User> = []
export const impersonateInitialState: boolean = false

export const userReducer  = createReducer(
    initialState,
    on(userDetails,(state, {user}) => user)
)

export const impersonateReducer = createReducer(
    impersonateInitialState,
    on(impersonateAction, (state, { impersonate }) => impersonate)
)