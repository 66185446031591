import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";
// import { do, delay, retry} from 'rxjs/add/operator';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor{
    intercept(request: HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
        console.log('Interceptor calling')
        return next.handle(request).pipe(
            retry(1)
        )
    }
}