import { createReducer, on } from "@ngrx/store";
import { headerUpdateAction,
    eventthumbnailClickAction,
    toggleRestrictionAction,updateDuplicateGreensEventAction,TemplateIdAction,eventIdAction ,greenIdAction,toggleDetailsAction,templateIdAction,templateLabelAction,greensThumbnailAction} from "../actions/events.actions";

export const toggleRestrictionInitialState: boolean = false
export const headerTextUpdateInitialState: boolean = false
export const updateDuplicateGreensInitialState: boolean = false
export const toggleDetailsInitialState: boolean = false
export const eventthumbnailClickInitialState: boolean = false
export const greensThumbnailState = []
export const getTemplateId: any=''
export const geteventId: any=''
export const getgreenId: any=''
export const getTemplateID: any=''
export const getTemplateName: any=''

export const manageToggleRestrictionReducer = createReducer(
    toggleRestrictionInitialState,
    on(toggleRestrictionAction, (state, { toggleRestriction }) => toggleRestriction)
)
export const manageHeaderTextUpdateReducer = createReducer(
    headerTextUpdateInitialState,
    on(headerUpdateAction, (state, { headerTextUpdate }) => headerTextUpdate)
)
export const manageUpdateDuplicateGreensReducer = createReducer(
    updateDuplicateGreensInitialState,
    on(updateDuplicateGreensEventAction, (state, { updateDuplicate }) => updateDuplicate)
)
export const templateIdReducer = createReducer(
    getTemplateId,
    on(TemplateIdAction, (state, { templateId }) => templateId)
)
export const eventIdReducer = createReducer(
    geteventId,
    on(eventIdAction, (state, { eventId }) => eventId)
)
export const greenIdReducer = createReducer(
    getgreenId,
    on(greenIdAction, (state, { greenId }) => greenId)
)
export const manageToggleDetailsReducer = createReducer(
    toggleDetailsInitialState,
    on(toggleDetailsAction, (state, { toggleDetailspage }) => toggleDetailspage)
)
export const TemplateIDReducer = createReducer(
    getTemplateID,
    on(templateIdAction, (state, { templateID }) => templateID)
)
export const templateNameReducer = createReducer(
    getTemplateName,
    on(templateLabelAction, (state, { templateName}) => templateName)
)

export const eventGreensThumbnailReducer = createReducer(
    greensThumbnailState,
    on(greensThumbnailAction, (state, { greenThumbnail }) => greenThumbnail)
)
export const eventthumbnailClickReducer = createReducer(
    eventthumbnailClickInitialState,
    on(eventthumbnailClickAction, (state, { thumbnailClick }) => thumbnailClick)
)
