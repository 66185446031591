import { Action, createReducer, on } from "@ngrx/store";
import { greensThumbnail, pinsheetDetails, updatePinsheetAction, pinsheetDateAction, updateGreenSpeed, updateDaysOfHistory, thumbnailClickAction, ColorIndicationToggleAction } from "../actions/pinsheet.action";
import { Pinsheet } from "../models/pinsheet.model";

export const initialState = []
export const greensThumbnailState = []
export const pinsheetDateState = ''
export const greenSpeedInitialState = 1
export const daysHistoryInitialState = 1
export const updatePinsheetInitialState = false
export const thumbnailClickInitialState = false
export const colorIndicationInitialState = false
export const pinsheetReducer = createReducer(
    initialState,
    on(pinsheetDetails, (states, { pinsheet }) => pinsheet),
    // on(updatePinsheet, state => ({ ...state, HasUpdated: true })),

)
export const greenSpeedReducer = createReducer(
    greenSpeedInitialState,
    on(updateGreenSpeed, (state, { greenSpeed }) => greenSpeed)
)
export const daysHistoryReducer = createReducer(
    daysHistoryInitialState,
    on(updateDaysOfHistory, (state, { daysHistory }) => daysHistory)
)

export const updatePinsheetReducer = createReducer(
    updatePinsheetInitialState,
    on(updatePinsheetAction, (state, { pinsheetUpdate }) => pinsheetUpdate)
)

// export function pinsheetReducer(state: any = initialState, action: any) {
//     switch (action.type) {
//         case updateGreenSpeed.type:
//             console.log(action.greenSpeed)
//             console.log(state)
//             return state
//         case pinsheetDetails.type:
//             let pinsheet = action.pinsheet
//             return { ...state, pinsheet }
//         default:
//             return state

//     }
// }

export const greensThumbnailReducer = createReducer(
    initialState,
    on(greensThumbnail, (state, { greenThumbnail }) => greenThumbnail)
)

export const pinsheetDateReducer = createReducer(
    pinsheetDateState,
    on(pinsheetDateAction, (state, { pinsheetDate }) => pinsheetDate)
)
export const thumbnailClickReducer = createReducer(
    thumbnailClickInitialState,
    on(thumbnailClickAction, (state, { thumbnailClick }) => thumbnailClick)
)
export const colorIndicationToggleReducer = createReducer(
    colorIndicationInitialState,
    on(ColorIndicationToggleAction, (state, { colorIndication }) => colorIndication)
)
