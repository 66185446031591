import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SecureMemberPagesGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.authService.isMemberLoggedIn){
        this.router.navigate(['member/pinsheet'])
      }
      else {
        console.log('Not logged in')
        const queryParams = route.queryParams;
        const codeParam = queryParams['code'];
        if(codeParam != null && codeParam != undefined) {
          console.log('Code param present')
          this.router.navigate(['/member/pinsheet'], {queryParams: queryParams})
        }
      }
    return true;
  }
  
}
