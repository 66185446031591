import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsHandler } from './error-handler/error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerErrorsInterceptor } from './server-errors-interceptor/server-errors.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ServerErrorsInterceptor,
      multi:true
    }
  ]
})
export class ErrorModule { }
