import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'EzLocator';

  constructor( private router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    const bc = new BroadcastChannel("ezlo-web");
    bc.onmessage = (event) => {
      bc.close()
      if(event.data == 'logout') {
        setTimeout(() => {
          this.router.navigate(['login'])
        }, 2000);
      }
      if(event.data == 'login') {
        let queryParams: any;
        this.activatedRoute.queryParams.subscribe(params => {
          queryParams = params;
          console.log(queryParams);
        })
        let returnUrl: string = queryParams['returnUrl']
        console.log(returnUrl.includes(environment.CLIENT_ID), '***brodcast-returnUrl-check')
        let url = returnUrl.includes(environment.CLIENT_ID) ? window.location.origin + '/login' : environment.ADMIN_URL
        setTimeout(() => {
          window.location.href = url
        }, 3000);
      }
    }
  }
}
