import { createAction, props } from "@ngrx/store";

export const toggleRestrictionAction = createAction(
    '[Toggle events restriction]', props<{ toggleRestriction: boolean }>()
)
export const headerUpdateAction = createAction(
    'Is Header content Updated', props<{ headerTextUpdate: boolean }>()
)
export const updateDuplicateGreensEventAction = createAction(
    'Update duplicate greens setting', props<{ updateDuplicate: boolean }>()
)
export const TemplateIdAction = createAction(
    'Get Template Id', props<{ templateId: any }>()
)
export const eventIdAction = createAction(
    'Get event Id', props<{ eventId: any }>()
)
export const greenIdAction = createAction(
    'Get Green Id', props<{ greenId: any }>()
)
export const toggleDetailsAction = createAction(
    '[Toggle events restriction]', props<{ toggleDetailspage: boolean }>()
)
export const templateIdAction = createAction(
    'Get Hole Id', props<{ templateID: any }>()
)
export const templateLabelAction = createAction(
    'Get Hole Value', props<{ templateName: any }>()
)

export const greensThumbnailAction = createAction(
    '[Greens Thumbnail] retrieve greens thumbnail', props<{ greenThumbnail: [] }>()
)

export const eventthumbnailClickAction = createAction(
    'Thumbnail clicked', props<{ thumbnailClick: any }>()
)