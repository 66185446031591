import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MemberAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService:CookieService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.authService.isMemberLoggedIn){
        console.log('Not logged in')
        const queryParams = route.queryParams;
        const codeParam = queryParams['code'];
        if(codeParam != null && codeParam != undefined) {
          // this.router.navigate(['/member'], {queryParams: {code: codeParam}})
        } else {
          this.router.navigate(['/member'])
        }
      }
    return true;
  }
  
}
