import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { impersonateAction, userDetails } from 'src/app/store/actions/user.actions';
import { selectUserCollection, selectUserImpersonate } from 'src/app/store/selectors/user.selectors';
import { LoginService } from '../../login/login.service';
import { pinsheetDateAction } from 'src/app/store/actions/pinsheet.action'
import { selectClientsCollection, selectClientsProfileCollection } from 'src/app/store/selectors/clients.selectors';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpService } from 'src/app/core/services/http/http.service';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class ContentComponent implements OnInit {
  UserRoleID:number;
  superAdmin:boolean;
  user:boolean;
  member: boolean;
  user$ = this.store.select(selectUserCollection)
  userData: any;
  impersonate: boolean;
  impersonate$ = this.store.select(selectUserImpersonate)
  clientsData$ = this.store.select(selectClientsCollection)
  selectedClientName: string;
  userRights: string
  showBanner: boolean = false;
  hamburgerElement: ElementRef<any>;
  isMobile: boolean = false;
  desktop: boolean = false;
  hamburgerClicked: boolean = false;
  selectedPage: string = 'pinsheet';
  clientsProfile$ = this.store.select(selectClientsProfileCollection)
  clientProfileData: any
  memberFlyerQRCodeURL: string;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize()
  }

  checkScreenSize() {
    if (window.innerWidth <= 480) {
      this.isMobile = true
      this.setSelectedPage()
    } else {
      this.isMobile = false
    }
    this.desktop = window.innerWidth >= 1200
  }

  setSelectedPage(page: string = '') {
    let path = window.location.pathname
    if(page == '') {
      if(path.includes('/pinsheet')) {
        this.selectedPage = 'pinsheet'
      }
      else if (path.includes('/manage')) {
        this.selectedPage = 'manage'
      }
      else if (path.includes('/events')) {
        this.selectedPage = 'events'
      }
      else if(path.includes('/help')) {
        this.selectedPage = 'help'
      }
      else {
        this.selectedPage = ''
      }
    }
    else {
      this.selectedPage = page
    }
  }
  
  ngOnInit(): void {
    this.checkScreenSize()
    let impersonate = this.cookieService.get('onImpersonate') == '1'
    this.store.dispatch(impersonateAction({impersonate}))
    this.showBanner =  impersonate


    let token = this.cookieService.get(this.impersonate ? 'impersonateRefreshToken' : 'refreshToken')
    if(token)
      this.httpService.refreshExpirationCounter(this.httpService.getTokenExpiry(token)*1000)
    
    this.loginService.getUserInfo()
    .subscribe((user)=>{
      if(user.Status == 'Success'){
        user = user.Data
        this.store.dispatch(userDetails({user}))
        this.cookieService.set('loggedInUserId', user.UserId)
      }
    })

    document.addEventListener("visibilitychange", () => {
      let token = this.cookieService.get(this.impersonate ? 'impersonateRefreshToken' : 'refreshToken')
      if(!document.hidden && this.httpService.getTokenExpiry(token) <= 0) {
        this.httpService.tokenExpiredCallback()
      }
    })
  }
  resetDate(){
    let pinsheetDate = undefined
    this.store.dispatch(pinsheetDateAction({ pinsheetDate }))
  }

  setUserRight(data: any) {
    switch(data.RightsId) {
      case 1:
        this.userRights = 'Super Admin'
        break;
      case 2:
        this.userRights = 'Admin'
        break;
      case 3:
        this.userRights = 'Manager'
        break;
      case 4:
        this.userRights = 'User'
        break;
      case 5:
        this.userRights = 'Member'
        break;
      default:
        break;
    }
  }

  openSideNavMenu(event: ElementRef<any>) {
    this.hamburgerElement = event
    this.hamburgerClicked = true
  }

  closeSideNavMenu() {
    this.hamburgerClicked = false
  }

  constructor(
    private cookieService: CookieService,
    private store:Store,
    private loginService:LoginService,
    private renderer: Renderer2,
    private httpService: HttpService
    ) { 
      // console.log(this.user$,'redUx store')
      this.user$.subscribe((data)=>{
        this.userData = data
        this.UserRoleID = data.RightsId
        this.superAdmin = this.UserRoleID == 1
        this.user = this.UserRoleID == 4
        this.member = this.UserRoleID == 5
        this.setUserRight(data)
      })
      this.impersonate$.subscribe((data) => {
        this.impersonate = data
      })
      this.clientsData$.subscribe((data) => {
        data.filter((v) => {
          if (Number(v.id) == Number(this.cookieService.get('clientId'))) {
            this.selectedClientName = v.ClientName
          }
        })
      })
      this.renderer.listen('document', 'click', (e:Event) => {
        let target = <HTMLElement>e.target;
        let mobileSidenav = document.getElementById("mobileSidenav")
        if(mobileSidenav && !mobileSidenav.contains(target) && this.hamburgerElement.nativeElement != target && this.hamburgerElement &&
          this.hamburgerElement.nativeElement != target.parentElement) {
            if(this.isMobile && this.hamburgerClicked) {
              this.closeSideNavMenu()
            }
        }
        this.clientsProfile$.subscribe((data) => {
          this.clientProfileData = data
          this.memberFlyerQRCodeURL = `${location.origin}/member?code=${this.clientProfileData.MemberPassword}`
        })
        // if(this.hamburgerElement && this.hamburgerElement.nativeElement != target && 
        //   target.parentElement != this.hamburgerElement.nativeElement && target != this.mobileSidenav.nativeElement) {
        //     if(this.isMobile && this.hamburgerClicked) {
        //       this.hamburgerClicked = false
        //     }
        // }
      })
  }

}
