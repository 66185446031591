import { Component, OnInit, Input, Output, EventEmitter, Inject, SimpleChanges, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
const ESCAPE_KEYCODE = 27;
@Component({
  selector: 'app-impersonate-dropdown',
  templateUrl: './impersonate-dropdown.component.html',
  styleUrls: ['./impersonate-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonateDropdownComponent implements OnInit {

  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  user: boolean;
  superAdmin: boolean;
  hideClients: boolean = true;
  myControl = new FormControl();
  @Input() label: string;
  @Input() dropdownList: any[] = [];
  chips = [];
  filteredOptions: Observable<any[]>;
  @Input() page: string = '';
  @Input() isMobile: boolean = false;
  @Output() setSelected = new EventEmitter<string>();
  @Output() selectedId = new EventEmitter<string>();
  @HostListener('window:click', ['$event.target'])
  onClick(event: any) {
    let autoCompleteList = document.querySelector('.mat-autocomplete-visible')
    if (autoCompleteList == null && this.myControl.value == '') {
      this.myControl.setValue(this.label)
    }
  }
  @HostListener('document:keyup.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let autoCompleteList = document.querySelector('.mat-autocomplete-visible')
    if (autoCompleteList == null && this.myControl.value == '') {
      this.myControl.setValue(this.label)
      console.log(event, '&&&ESCAPEKEY&&&');
    }
  }


  constructor() {
    console.log(this.dropdownList, '***dropdown-list-imp1')
    if (this.dropdownList.length > 0) {
      console.log(this.dropdownList, '***dropdown-list-imp')
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this._filter(value)
        }),
      );
    }
  }
  selectValue(value: string, id: string) {
    document.getElementById('clientInput')?.blur()
    this.setSelected.emit(value)
    this.selectedId.emit(id)
    this.onchange(value)
  }
  onchange(selectedValue: string) {
    this.label = selectedValue
  }
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, '***on-changes-dropdown-imp')
    const currentChange = changes.label
    if (currentChange.currentValue) {
      this.label = currentChange.currentValue
      this.myControl.setValue(this.label)
    }
    const dropdownListChange = changes.dropdownList
    if(dropdownListChange.currentValue.length) {
      this.dropdownList = dropdownListChange.currentValue
      console.log(this.dropdownList, '***dropdown-list-imp')
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this._filter(value)
        }),
      );
    }
  }
  public _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.dropdownList.filter(item => item.name.toLowerCase().includes(filterValue));
  }

  selectText(event: any) {
    event.target.select()
  }

}
