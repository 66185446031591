<div class="btn-group dropdown" dropdown #dropdown="bs-dropdown" #hostElement (mouseleave)="dropdown.hide();">
    <button [disabled]="showcursor" [ngStyle]="{
        'cursor':showcursor ? 'not-allowed':'pointer',
        'background-color':manage?'#eeeeee':'#ffffff'
    }" dropdownToggle type="button" (mouseover)="fixDropdownWidth(hostElement);isFont ? '' : dropdown.show();"
        (click)="fixDropdownWidth(hostElement); toggleDropdown(dropdown)"
        aria-controls="dropdown-basic" 
        [ngClass]="{'button-basic-pinsheetGrid': page=='pinsheetGrid' || page == 'ext-pinsheet',
                    'button-basic-events': page=='events',
                    'title':manage,
                    'upperCase':page=='pinsheetGrid' || page=='impersonateDialog' || page=='ext-pinsheet',
                    'button-basic': page=='' || page =='formatSettings' || page=='myAccount',
                    'imp-dialog-button': page=='impersonateDialog',
                    'ext-pinsheeet-button': page == 'ext-pinsheet',
                    'button-basic-hMenu': page == 'hMenu',
                    'button-basic-myAccount': page == 'myAccount'}">
        {{label}}
        <img *ngIf="page == 'ext-pinsheet' && label" src="../../assets/images/tag.svg ">
        <span *ngIf="page=='impersonateDialog'" class="rights-label">{{userRight}}</span>
    </button>
    <ul class="dropdown-basic" [ngStyle]="{'visibility':Isshow ? 'hidden':'visible','zIndex':'99999999'}" *dropdownMenu
        class="dropdown-menu custom-dropdown " (mouseleave)="dropdown.hide();" role="menu "
        aria-labelledby=" button-basic ">
        <li *ngFor="let dropdown of dropdownArr let lastItem=last; " (click)="selectValue(dropdown.name,dropdown.id)"
            #dropdownvalue>
            <a class=" dropdown-item " [ngClass]="{'upperCase':page=='pinsheetGrid' || page=='impersonateDialog' || page == 'ext-pinsheet',
                                                    'reduce-font': page =='impersonateDialog',
                                                    'dropdown-item-myAccount': page == 'myAccount'}">{{dropdown.name}}
                <span *ngIf="page=='impersonateDialog'" class="rights-label">{{dropdown.UserRight}}</span>
            </a>
            <hr *ngIf="!lastItem " />
        </li>
    </ul>
</div>
<!-- [ngStyle]="{'visibility': dropdownArr.length ==1 ? 'hidden':'visible'}" -->