import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { Store } from '@ngrx/store';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginUrl = '/api/users/login'
  configUrl = '/assets/data/login.json'
  userInfoUrl = '/api/users/info'
  
  login(data:any){
    return this.http.post(this.loginUrl, data, false)
  }

  loginService(){
    return this.http.get(this.configUrl)
  }

  getUserInfo(){
    return this.http.get(this.userInfoUrl)
  }
  constructor(
    private http:HttpService,
    private store:Store) { }
}