import { createReducer, on } from "@ngrx/store";
import {
    selectThumbnail, selectedHoleAction,
    manageGreenThumbnailAction,
    enableDisableholeAction,
    closeStimpAction, activeHolesAction, UpdateHolesAction, holesUpdatedAction, clearSelectionAction, holsTableHoleAction
} from "../actions/manage-green.action";

export const initialState: any = ''
export const initialSelectedHolesState: any = []
export const initialHolesUpdated: any = false
export const initialActiveHolesState: any = []
export const closeStimpTableInitialState: boolean = false
export const UpdateHolesInitialState: boolean = false
export const clearSelectionInitialState: boolean = false
export const enableDisableholeInitialState: boolean = false
export const manageGreenThumbnailInitialState: any = []
export const holesTableInitialState: any = []


export const holesTableReducer = createReducer(
    holesTableInitialState,
    on(
        holsTableHoleAction,
        (state, { holesData }) => holesData
    )
)

export const selectAllManageGreenThumbnailReducer = createReducer(
    manageGreenThumbnailInitialState,
    on(
        manageGreenThumbnailAction,
        (state, { greenThumbnailData }) => greenThumbnailData
    )
)

export const selectManageGreenThumbNailReducer = createReducer(
    initialState,
    on(selectThumbnail, (state, { greenId }) => {
        console.log("greenId")
        let selectedManageGreenThumbnailId = greenId
        return selectedManageGreenThumbnailId
    })
)

export const manageSelectedHolesReducer = createReducer(
    initialSelectedHolesState,
    on(
        selectedHoleAction, (state, { selectedHoles }) => selectedHoles
    )
)
export const manageHolesUpdatedReducer = createReducer(
    initialHolesUpdated,
    on(
        holesUpdatedAction, (state, { holesUpdated }) => holesUpdated
    )
)

export const manageCloseStimpTableReducer = createReducer(
    closeStimpTableInitialState,
    on(
        closeStimpAction, (state, { closeStimpTable }) => closeStimpTable
    )
)
export const manageActiveHolesReducer = createReducer(
    initialActiveHolesState,
    on(
        activeHolesAction, (state, { activeHoles }) => activeHoles
    )
)
export const manageDeleteReducer = createReducer(
    UpdateHolesInitialState,
    on(
        UpdateHolesAction, (state, { holesApi }) => holesApi
    )
)
export const clearSelectionReducer = createReducer(
    clearSelectionInitialState,
    on(
        clearSelectionAction, (state, { clearSelection }) => clearSelection
    )
)
export const enableDisableholeReducer = createReducer(
    enableDisableholeInitialState,
    on(
        enableDisableholeAction, (state, { enableDisable }) => enableDisable
    )
)
