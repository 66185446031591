import { createReducer, on } from "@ngrx/store";
import { formatDetails, layoutSettingsDetails,
    formatChangeAction,EventsFormatChangeAction,
    GreenTextChangeAction,
    courseListFormatAction, displayLoader, displayThumbnailLoader, specialPinsheetFormatDetails,
    formatHeaderTextUpdate, formatSpecialHeaderTextUpdate, formatHeaderImageUpdate, formatSpecialHeaderImageUpdate
 } from "../actions/format.action";
import { Format } from "../models/format.model";
import { Layouts } from "../models/layout.model";

export const specialPinsheetFormatInitialState: ReadonlyArray<Format> = []
export const initialState: ReadonlyArray<Format> = []
export const layoutSettingsInitialState: ReadonlyArray<Layouts> = []
export const showLoaderInitialState: boolean = true
export const showThumbnailLoaderInitialState: boolean = true
export const courseListState: any = []
export const formatChangeInitiaState: boolean = false
export const eventsFormatChangeInitiaState: boolean = false
export const geenTextChangeActionInitiaState: any = []
export const formatHeaderTextState: any = ''
export const formatSpecialHeaderTextState: any = ''
export const formatHeaderImageState: any = ''
export const formatSpecialHeaderImageState: any = ''

export const formatReducer = createReducer(
    initialState,
    on(formatDetails, (states, { formatData }) => formatData)
)
export const specialPinsheetFormatReducer = createReducer(
    specialPinsheetFormatInitialState,
    on(specialPinsheetFormatDetails, (states, { specialPinsheetFormatData }) => specialPinsheetFormatData)
)
export const layoutSettingsReducer = createReducer(
    layoutSettingsInitialState,
    on(layoutSettingsDetails, (states, { layoutSettings }) => layoutSettings)
)

export const displayLoaderReducer = createReducer(
    showLoaderInitialState,
    on(displayLoader, (state, { showLoader }) => showLoader)
)

export const displayThumbnailLoaderReducer = createReducer(
    showThumbnailLoaderInitialState,
    on(displayThumbnailLoader, (state, { showLoader }) => showLoader)
)

export const courseListReducer = createReducer(
    courseListState,
    on(courseListFormatAction, (state, { courseList }) => courseList)
)
export const formatChangedReducer = createReducer(
    formatChangeInitiaState,
    on(formatChangeAction, (state, { formatChanged }) => formatChanged)
)
export const eventsFormatChangedReducer = createReducer(
    eventsFormatChangeInitiaState,
    on(EventsFormatChangeAction, (state, { eventsFormatChanged }) => eventsFormatChanged)
)

export const greenTextChangeReducer = createReducer(
    geenTextChangeActionInitiaState,
    on(GreenTextChangeAction, (state, { fontProperty }) => fontProperty)
)

export const formatHeaderTextReducer = createReducer(
    formatHeaderTextState,
    on(formatHeaderTextUpdate, (state, { formatHeaderText }) => formatHeaderText)
)

export const formatSpecialHeaderTextReducer = createReducer(
    formatSpecialHeaderTextState,
    on(formatSpecialHeaderTextUpdate, (state, { formatSpecialHeaderText }) => formatSpecialHeaderText)
)

export const formatHeaderImageReducer = createReducer(
    formatHeaderImageState,
    on(formatHeaderImageUpdate, (state, { formatHeaderImage }) => formatHeaderImage)
)

export const formatSpecialHeaderImageReducer = createReducer(
    formatSpecialHeaderImageState,
    on(formatSpecialHeaderImageUpdate, (state, { formatSpecialHeaderImage }) => formatSpecialHeaderImage)
)