import { createAction, props } from "@ngrx/store";
import { Format } from "../models/format.model";
import { Layouts } from "../models/layout.model";

export const formatDetails = createAction(
    '[Format pinsheet details] retrieve format pinsheet list', props<{ formatData: ReadonlyArray<Format> }>()
)
export const specialPinsheetFormatDetails = createAction(
    '[Format Special pinsheet details] retrieve format pinsheet list', props<{ specialPinsheetFormatData: ReadonlyArray<Format> }>()
)

export const layoutSettingsDetails = createAction(
    '[Layout settings data] retrieve layout settings data', props<{ layoutSettings: ReadonlyArray<Layouts> }>()
)

export const displayLoader = createAction(
    '[Display loader] show loader on fetching data', props<{ showLoader: boolean }>()
)
export const displayThumbnailLoader = createAction(
    '[Display loader] show loader on fetching data', props<{ showLoader: boolean }>()
)
export const displayManageThumbnailLoader = createAction(
    '[Display loader] show loader on fetching data', props<{ showLoader: boolean }>()
)
export const courseListFormatAction = createAction(
    '[Course list]', props<{ courseList: any }>()
)
export const formatChangeAction = createAction(
    '[Course format change action]', props<{ formatChanged: boolean }>()
)
export const EventsFormatChangeAction = createAction(
    '[Course format change action]', props<{ eventsFormatChanged: boolean }>()
)
export const GreenTextChangeAction = createAction(
    '[Green Name font change action]', props<{ fontProperty: any }>()
)
export const formatHeaderTextUpdate = createAction(
    '[Format pinsheet headerText update]', props<{ formatHeaderText: any }>()
)
export const formatSpecialHeaderTextUpdate = createAction(
    '[Format pinsheet SpecialHeaderText update]', props<{ formatSpecialHeaderText: any }>()
)
export const formatHeaderImageUpdate = createAction(
    '[Format Special pinsheet HeaderImage update]', props<{ formatHeaderImage: any }>()
)
export const formatSpecialHeaderImageUpdate = createAction(
    '[Format Special pinsheet SpecialHeaderImage update]', props<{ formatSpecialHeaderImage: any }>()
)