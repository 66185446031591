import { createAction, props } from "@ngrx/store";
import { ManagePinsheet } from "../models/manage-pinsheet.model";

export const managePinsheetdetails = createAction(
    '[Manage Pinsheet Details] retrieve pinsheet details',props<{managePinsheet:ManagePinsheet[]}>()
)
export const toggleAvoidableRadius = createAction(
    '[Toggle Avoidable radius] on/off avoidable radius',props<{avoidableRadius:boolean}>()
)
export const toggleFuturePins = createAction(
    '[Toggle Avoidable radius] on/off avoidable radius',props<{futurePins:boolean}>()
)
export const toggleHistoryFlag = createAction(
    '[Toggle Avoidable radius] on/off avoidable radius',props<{historyFlag:boolean}>()
)
export const redirectPinsheet = createAction(
    'Redirect to pinsheet',props<{redirect:boolean}>()
)
export const togglePinsheetHistory = createAction(
    '[Toggle Pinsheet History] on/off',props<{pinsheetHistory:boolean}>()
)
export const getPinsheetHistory = createAction(
    '[get Pinsheet History] on/off',props<{dayHistory:boolean}>()
)