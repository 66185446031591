import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input,SimpleChanges, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges, OnInit {
  @Input() isFont:boolean = false
  @Input() label: string = '';
  @Input() dropdownArr: any[] = [];
  @Input() page: string = '';
  @Output() setSelected = new EventEmitter<string>();
  @Output() selectedId = new EventEmitter<string>();
  @Input() Isshow: boolean;
  @Input() showcursor: boolean;
  @Input() manage:boolean = false;
  @Input() userRight: string = '';

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private cookieService:CookieService
  ) { }


  selectValue(value:string,id:string){
    this.setSelected.emit(value)
    this.selectedId.emit(id)
    if(this.page == 'impersonateDialog') {
      this.userRight = ''
      this.setRight(id)
    }
    this.onchange(value)
  }

  onchange(selectedValue:string){
    this.label = selectedValue
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //this.label = 'SELECT COURSE'
    const currentChange = changes.label
    if(currentChange.currentValue){
      this.label = currentChange.currentValue
    }
  }

  fixDropdownWidth(hostElement: HTMLDivElement) {
    setTimeout(() => {
      let childWidth = hostElement.clientWidth;
      let child = document.getElementById('dropdown-basic')
      if (child) {
        child.style.width = childWidth + 'px'
      }
    }, 0)
  }

  toggleDropdown(dropdown: BsDropdownDirective) {
    if(this.page != 'formatSettings' && this.page != 'events' && this.page != 'myAccount' && this.page != 'impersonateDialog') {
      if(this.isFont) {
          dropdown.show()
      }
    }
  }

  setRight(id: string) {
    let selectedUser = this.dropdownArr.find((v: any) => v.id == id)
    console.log(selectedUser, '***selected-user')
    if(selectedUser.RightsId == 2) {
      this.userRight = selectedUser.UserRight
      console.log(this.userRight, '***user-right-set')
    }
  }
}
