import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ClientsProfile } from "../models/clients-profile.model";

export const user = createFeatureSelector<Record<string, string>[]>('clientsData')
export const clientProfile = createFeatureSelector<ClientsProfile[]>('clientProfile')
export const selectClientsCollection = createSelector(
    user,
    (clientsData) => clientsData
)
export const selectClientsProfileCollection = createSelector(
    clientProfile,
    (clientProfile) => clientProfile
)