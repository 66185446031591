export function printStyle() {
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    if (style.sheet) {
        console.log(style.sheet.cssRules); // length is 0, and no rules
    }
    return style;
}

export function addMediaPrintCss(margin: string = '0') {
    let id = 'printStyle'

    const existingPrintStyle = document.getElementById(id);
    if(existingPrintStyle)
        existingPrintStyle.remove();

    let style = document.createElement("style");
    style.id = id
    let printStyleCss = `
    @media print {
        @page {
            // size: auto !important;
            margin: ${margin} !important;
        }
    }
    `
    style.textContent = printStyleCss;
    document.head.appendChild(style);
}