import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ErrorsHandler implements ErrorHandler{
    constructor(private injector:Injector,cookie:CookieService){}
    handleError(error: Error | HttpErrorResponse){
        const router = this.injector.get(Router)
        if(error instanceof HttpErrorResponse){
            router.navigate(['/login'])
        }

    }
}