import { createReducer, on } from "@ngrx/store";
import { clientsDetails, clientsProfile } from "../actions/clients.actions";
import { ClientsProfile } from '../models/clients-profile.model'

export const initialState: Record<string, string>[] = []
export const clientsProfileInitialState: ClientsProfile[] = []

export const clientsReducer = createReducer(
    initialState,
    on(clientsDetails, (state, { clientsData }) => clientsData)
)

export const clientsProfileReducer = createReducer(
    clientsProfileInitialState,
    on(clientsProfile, (state, { clientsProfileData }) => clientsProfileData)
)