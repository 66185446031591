import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { catchError, delay } from "rxjs/operators";
import { Router } from '@angular/router';
import { logoutAction } from 'src/app/store/actions/logout.action';
import { Store } from '@ngrx/store';
import { selectUserImpersonate } from 'src/app/store/selectors/user.selectors';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl: string = environment.BASE_URL
  impersonate: boolean = false
  impersonate$ = this.store.select(selectUserImpersonate);
  tokenSubscription = new Subscription()
  logoutWarningSubscription = new Subscription()
  refreshInProgress: boolean = false
  refreshTokenObservers: any = []
  momentFun: any = moment
  activeCookieDomain:string = environment.ACTIVE_TIME_DOMAIN
  timer: any
  warningTimeBefore: number = 5*60
  refreshTokenDefaultExpiry: number = 12*60*60
  warningSnackBarRef: MatSnackBarRef<SnackbarComponent>

  constructor(
    private httpClient: HttpClient, 
    private cookieService: CookieService, 
    private router: Router, 
    private store: Store,
    private _snackBar: MatSnackBar
  ) { 
    this.impersonate$.subscribe((data) => {
      this.impersonate = data
    })
  }

  get(url: string, params: any = {}, useAuth: boolean = true): Observable<any> {
    return new Observable((observer) =>
    {
      this.getAccessToken()
      .pipe(
        catchError((err) => {
          return throwError(err)
        })
      )
      .subscribe((accessToken: any) => {
        if(!accessToken) {
          this.logout()
          return observer.next(null)
        }

        let headers = new HttpHeaders()
        accessToken = this.cookieService.get('accessToken')
        if(this.impersonate) {
          accessToken = this.cookieService.get('impersonateAccessToken')
        }
        if (useAuth) {
          if (accessToken) {
            headers = headers.set('Authorization', `Bearer ${accessToken}`)
          } else {
            console.warn("No Access Token found")
          }
        }
        const httpOptions = {
          headers: headers,
          params: params
        }
        return this.httpClient.get(this.baseUrl + url, httpOptions).pipe(
          catchError((err) => {
            console.log('error caught in service')
            if (err.status == 401) {
              this.logout()
            }
            console.error(err.status, 'Status**');
            return throwError(err);
          })
        ).subscribe((data: any) => {
          return observer.next(data)
        })
      })
    }) 
  }

  post(url: string, data: any, useAuth: boolean = true, formData: boolean = false): Observable<any> {
    return new Observable((observer) => {
      console.log('HTTPService: post ', url)
      let refreshToken = this.cookieService.get('refreshToken')
      if(this.impersonate) {
        refreshToken = this.cookieService.get('impersonateRefreshToken')
      }
      if (refreshToken) {
        this.getAccessToken()
        .pipe(
          catchError((err) => {
            return throwError(err)
          })
        )
        .subscribe((accessToken: any) => {
          if(!accessToken) {
            this.logout()
            return observer.next(null)
          }
          this.postApiCall(url, data, useAuth, formData)
            .subscribe((data:any) => {
              return observer.next(data)
            })
        })
      }
      //for login API
      else {
        this.postApiCall(url, data, useAuth, formData)
          .subscribe((data:any) => {
            return observer.next(data)
          })
      }
    })
  }

  postApiCall(url: string, data: any, useAuth: boolean = true, formData: boolean = false): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let accessToken = this.cookieService.get("accessToken")
    if(this.impersonate) {
      accessToken = this.cookieService.get('impersonateAccessToken')
    }
    if (useAuth) {
      if (accessToken) {
        headers = headers.set('Authorization', `Bearer ${accessToken}`)
      }
    }
    if (formData) {
      headers = new HttpHeaders()
      headers = headers.set('Authorization', `Bearer ${accessToken}`)
    }
    let httpOptions : any = {
      headers: headers
    };
    console.log(httpOptions, '**http-options')
    return this.httpClient.post(this.baseUrl + url, data, httpOptions).pipe(
      catchError((err) => {
        console.log('error caught in service')
        if (err.status == 401) {
          this.logout()
        }
        console.error(err);
        return throwError(err);
      })
    )
  }

  logout() {
    this.tokenSubscription.unsubscribe();
    if(this.cookieService.get('externalSession') == 'true'){
      this.store.dispatch(logoutAction())
      this.cookieService.delete('accessToken')
      this.cookieService.delete('clientId')
      this.cookieService.delete('courseId')
      this.cookieService.delete('selectedPinsheetDate')
      this.cookieService.delete('pinsheetDate')
      this.cookieService.delete('loggedInUserId')
      this.cookieService.delete('refreshToken')
      this.cookieService.delete('userId')
      this.cookieService.delete('onImpersonate')
      this.cookieService.delete('impersonateRefreshToken')
      this.cookieService.delete('impersonateAccessToken')
      this.cookieService.delete('clientBeforeImpersonate')
      this.cookieService.delete('courseBeforeImpersonate')
      this.cookieService.delete('externalSession')
      this.cookieService.delete('lastActive', undefined, this.activeCookieDomain)
      this.router.navigate([''])
    }
    else {
      let idToken = this.cookieService.get('idToken')
      window.location.href = `${environment.BASE_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/logout`
    }
  }

  refresh(refreshToken: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })

    const httpOptions = {
      headers: headers
    }

    if(this.impersonate) {
      let nonImpRefreshToken = this.cookieService.get('refreshToken')
      return this.httpClient.get(this.baseUrl + `/api/users/refresh?refreshToken=${refreshToken}&tokenToUpdate=${nonImpRefreshToken}`, httpOptions)
    }
    return this.httpClient.get(this.baseUrl + `/api/users/refresh?refreshToken=${refreshToken}`, httpOptions)
  }

  getAccessToken(): Observable<any> {
    return new Observable((observer) => {
      let accessToken = this.cookieService.get('accessToken')
      if(this.impersonate) {
        accessToken = this.cookieService.get('impersonateAccessToken')
      }

      if(!accessToken) {
        return observer.next(null);
      }
      // const expiry = (JSON.parse(atob(accessToken.split('.')[1]))).exp
      if (this.getTokenExpiry(accessToken) > 120) {
        return observer.next(accessToken)
      }

      // access token will expire soon, refresh it
      let refreshToken = this.cookieService.get('refreshToken')
      if(this.impersonate) {
        refreshToken = this.cookieService.get('impersonateRefreshToken')
      }
      if(!refreshToken) {
        return observer.next(null);
      }

      if(!this.refreshInProgress) {
        this.refreshTokenObservers = []
        this.refreshTokenObservers.push(observer);
        this.refreshInProgress = true
        this.refresh(refreshToken)
          .pipe(
            catchError((err) => {
              console.error('refreshToken call failed', err)
              if (err.status == 401) {
                this.logout()
              }
              return throwError(err)
            })
          )
          .subscribe((data: any) => {
            this.refreshInProgress = false
            if (data.Status == 'Success') {
              if(this.impersonate) {
                this.setTokensAndCounter('impersonateAccessToken', 'impersonateRefreshToken', data)
              }
              else {
                this.setTokensAndCounter('accessToken', 'refreshToken', data)
              }
              this.setLastActiveCookie()
              this.refreshTokenObservers.map((o: any) => o.next(data.AccessToken));
              this.refreshTokenObservers = []
              // return observer.next(data.AccessToken)
            } else {
              console.error("refreshToken API returned error", data)
              return observer.next(null)
            }
          })
      }
      else {
        this.refreshTokenObservers.push(observer);
      }
    })
  }

  getTokenExpiry(token: string) {
    return JSON.parse(atob(token.split('.')[1])).exp - (Math.floor((new Date).getTime() / 1000))
  }

  refreshExpirationCounter(timeout: any) {
    console.log(timeout, '***timeout')
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
      console.log('EXPIRED!!');
      this.tokenExpiredCallback();
    });
    this.logoutWarningCounter(timeout - this.warningTimeBefore*1000)
  }

  logoutWarningCounter(timeout: any) {
    console.log(timeout, '***Warning-timeout')
    this.logoutWarningSubscription.unsubscribe();
    this.clearTimer();
    this.dismissSnackBar();
    this.logoutWarningSubscription = of(null).pipe(delay(timeout)).subscribe((warned) => {
      console.log("Warned!!")
      let lastActive = this.cookieService.get('lastActive')
      if(lastActive) {
        console.log('Has Last Active')
        let currenttime = (new Date).getTime()
        let diff = (currenttime - (+lastActive))/1000

        if(diff >= (this.refreshTokenDefaultExpiry - this.warningTimeBefore) && diff < this.refreshTokenDefaultExpiry) {
          this.openWarningSnackBar(this.refreshTokenDefaultExpiry - diff)
        }
        else {
          console.log(diff, " Difference between current time and last active")
        }
      }
      else {
        console.log('No Last Active')
        let refreshToken = this.cookieService.get(!this.impersonate ? 'refreshToken' : 'impersonateRefreshToken')
        console.log(this.getTokenExpiry(refreshToken))
        this.openWarningSnackBar(this.getTokenExpiry(refreshToken))
      }
    })
  }

  getWarningMessage(time: any) : string {
    let message = `You will be logged out within`
    let minutes = Math.floor(time/60)
    let seconds = Math.floor(time%60)
    if(minutes > 0) {
      message = message + ` ${minutes} minute${minutes > 1 ? 's' : ''}`
    }
    if(seconds > 0) {
      message = message + ` ${seconds} second${seconds > 1 ? 's': ''}`
    }
    return message
  }

  getSeconds() {
    let seconds
    let lastActive = this.cookieService.get('lastActive')
    if(lastActive) {
      let currenttime = (new Date).getTime()
      seconds = (this.refreshTokenDefaultExpiry) - (currenttime - (+lastActive))/1000
    }
    else {
      let refreshToken = this.cookieService.get(!this.impersonate ? 'refreshToken' : 'impersonateRefreshToken')
      seconds = this.getTokenExpiry(refreshToken)
    }

    return seconds
  }

  openWarningSnackBar(seconds: number) {
    this.warningSnackBarRef = this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: this.getWarningMessage(seconds),
        actions: ['Dismiss', 'Keep me logged in'],
        actionClick: (action: string) => this.warningSnackbarActionClicked(action)
      },
      duration: seconds * 1000,
      horizontalPosition: "end",
      verticalPosition: "top",
      panelClass: 'warningSnack'
    })

    this.timer = setInterval(() => {
      seconds = this.getSeconds()
      if(seconds > 0 && seconds <= this.warningTimeBefore) {
        this.warningSnackBarRef.instance.data.message = this.getWarningMessage(seconds)
      }
      else {
        this.clearTimer()
        this.dismissSnackBar()
      }
    }, 1000)
  }

  warningSnackbarActionClicked(action: string) {
    if(action == 'Dismiss') {
      console.log('Dismiss Clicked')
    }
    else if(action == 'Keep me logged in') {
      console.log('Keep me logged in Clicked')
      let refreshToken = this.cookieService.get('refreshToken')
      if(this.impersonate) {
        refreshToken = this.cookieService.get('impersonateRefreshToken')
      }

      this.refresh(refreshToken)
      .pipe(
        catchError((err) => {
          console.error('refreshToken call failed', err)
          if (err.status == 401) {
            this.logout()
          }
          return throwError(err)
        })
      )
      .subscribe((data: any) => {
        this.refreshInProgress = false
        if (data.Status == 'Success') {
          if(this.impersonate) {
            this.setTokensAndCounter('impersonateAccessToken', 'impersonateRefreshToken', data)
          }
          else {
            this.setTokensAndCounter('accessToken', 'refreshToken', data)
          }
          this.setLastActiveCookie()
        } else {
          console.error("refreshToken API returned error", data)
        }
      })

    }
    this.clearTimer()
    this.dismissSnackBar()
  }

  setTokensAndCounter(accessTokenName: string, refreshTokenName: string, data: any) {
    this.cookieService.set(accessTokenName, data.AccessToken)
    this.cookieService.set(refreshTokenName, data.RefreshToken)
    this.refreshExpirationCounter(this.getTokenExpiry(data.RefreshToken)*1000)
  }


  tokenExpiredCallback() {
    let lastActive = this.cookieService.get('lastActive')
    let diff = (new Date).getTime() - (+lastActive)
    
    if(diff/1000 < this.refreshTokenDefaultExpiry) {
      this.cookieService.delete('accessToken')
      this.cookieService.delete('refreshToken')
      this.cookieService.delete('idToken')
      this.router.navigate([''])
    }
    else {
      this.logout();
    }
  }

  setLastActiveCookie() {
    let epochTime = (new Date).getTime()
    this.cookieService.set('lastActive', epochTime.toString(), { domain: this.activeCookieDomain })
  }

  clearTimer() {
    if(this.timer) {
      clearInterval(this.timer)
    }
  }

  dismissSnackBar() {
    if(this.warningSnackBarRef) {
      this.warningSnackBarRef.dismiss();
    }
  }

}
