import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    // private router: Router,
    // private ngZone: NgZone,
    private cookieService:CookieService
  ) { }

  get isLoggedIn():boolean{
    const accessToken = this.cookieService.get('accessToken')
    return accessToken ? true : false
  }

  get isMemberLoggedIn(): boolean {
    const memberAccessToken = sessionStorage.getItem('memberAccessToken')
    return memberAccessToken !== null
  }
}
